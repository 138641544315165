import axios, { Axios, AxiosPromise } from "axios";
import envConfig from "../utils/envConfig";
import { UserListdetail } from "../models/User";
import { GenericResponse } from "../models/Common";
import { AWBDetails, UpdatedData } from "../models/TrackingSummary";
const LUPA_BASE_URL = envConfig.url.API_URL;
const MICRO_BASE_URL = envConfig.url.MICRO_TRACKING_URL;

export interface RegisterLupa {
  fullName: string;
  city: string;
  companyProfile: string;
  password: string;
  origin: string;
  email: string;
}
export type UserAirlineMapping = {
  id: string;
  userId: number;
  preferedDataSourceId1: number
  preferedDataSourceId2: number;
  airlineMappingId: string;
};
export const http = axios.create({
  baseURL: LUPA_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: false,
});

export const micro = axios.create({
  baseURL: MICRO_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: false,
});
export const lupaService = {
  login: (payload: { email: string; password: string }) => {
    try {
      return fetch(`${LUPA_BASE_URL}apiUser/getAPIKey`, {
        method: "POST",
        body: JSON.stringify(payload),

        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }

    // return http.post("/apiUser/auth/signin", payload, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
    //   },
    // });
  },
  addUser: (payload: {
    firstName: string;
    lastName: string;
    email: string;
    monthlyVolume?: number | undefined | null;
    role: string;
  }) => {
    return fetch(LUPA_BASE_URL + "apiUser/register", {
      method: "POST",
      body: JSON.stringify(payload),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  updateUserType: (userId: number, userType: "normal" | "lite") => {
    return fetch(LUPA_BASE_URL + "user/update-user-type", {
      method: "PUT",
      body: JSON.stringify({ userId, userType }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  },
  listUser: () => {
    return fetch(LUPA_BASE_URL + "/user/all-users", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  addAirline: (payload: {
    name: string;
    airlineCode: string;
    airlineType: string;
    dataSourcePreferences: object;
    // dataSource: string;
    // dateFormatTypesId: string;
  }) => {
    try {
      console.log(payload);
      return fetch(LUPA_BASE_URL + "apiUser/airline", {
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  postUserAirlineMapping: (payload: UserAirlineMapping) => {
    try {
      return fetch(LUPA_BASE_URL + "user-airline-mapping/", {
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  patchUserAirlineMapping: (payload: UserAirlineMapping) => {
    try {
      return fetch(LUPA_BASE_URL + "user-airline-mapping/" + payload.id, {
        method: "PATCH",
        body: JSON.stringify(payload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  deleteUserAirlineMapping: (
    userAirlineMappingId: string
  ) => {
    try {
      return fetch(LUPA_BASE_URL + "user-airline-mapping/" + userAirlineMappingId, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  addAirLineMilestone: (payload: {
    name: string;
    airlineCode: string;
    dataSource: string;
  }) => {
    try {
      console.log(payload);
      return fetch(LUPA_BASE_URL + "apiUser/availableAirlines", {
        method: "POST",
        body: JSON.stringify(payload),

        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  updateAirline: (payload: {
    name: string;
    airlineCode: string;
    airlineType: string;
    workflow: string;
    dataSourcePreferences: object;
  }) => {
    try {
      console.log(payload);
      return fetch(LUPA_BASE_URL + "apiUser/airline", {
        method: "PUT",
        body: JSON.stringify(payload),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  updateAirlineMilestone: (
    payload: {
      airlineCode: string | null | undefined;
      id: number;
      milestoneName: string;
      isStandard: boolean;
      standardMilestoneId: number | null;
      isRemark: boolean;
      type: "DELETED" | "UPDATED" | "ADDED" | "NONE";
    }[]
  ) => {
    try {
      return fetch(LUPA_BASE_URL + "apiUser/milestone", {
        method: "POST",
        body: JSON.stringify({ mileStoneData: payload }),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  IsActiveAirline: (airlineCode: string) => {
    try {
      return fetch(LUPA_BASE_URL + "apiUser/changeAirlines/" + airlineCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  addWebHook: (payload: { webhookUrl: string; hmacSecret: string }) => {
    try {
      const apikey = localStorage.getItem("userApiLupaJwtToken");
      return fetch(LUPA_BASE_URL + `apiUser/webhook?apiKey=${apikey}`, {
        method: "POST",
        body: JSON.stringify(payload),

        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  generateApiKey: () => {
    try {
      const apikey = localStorage.getItem("userApiLupaJwtToken");
      const email = localStorage.getItem("userApiemail");
      const payload = {
        email: email,
        apiKey: apikey,
      };
      return fetch(LUPA_BASE_URL + "apiUser/reGenerateKey", {
        method: "POST",
        body: JSON.stringify(payload),

        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  },
  getUser: function (): AxiosPromise<GenericResponse<UserListdetail[]>> {
    return http.get("user", {
      withCredentials: true,
    });
  },
  getWebhook: () => {
    const apikey = localStorage.getItem("userApiLupaJwtToken");
    return http.get(`${LUPA_BASE_URL}apiUser/webhook?apiKey=${apikey}`);
  },
  getPendingAWB: (airlineCode: string | any) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    return fetch(
      `${LUPA_BASE_URL}datasource/getAwb/${airlineCode}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Assuming the API returns JSON data
      })
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  changeDataSourcebulk: async (payload: any) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(
        `${LUPA_BASE_URL}datasource/change/bulk`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.text();
      console.log(result);
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  changeDataSourceLogs: async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        `${LUPA_BASE_URL}datasource/data/log`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
    }
  },
  changeDataSourceSwapLogs: async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        `${LUPA_BASE_URL}apiUser/dataSourceSwapLog`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      console.error(error);
    }
  },

  register: (payload: RegisterLupa) => {
    return http.post("ApiUser/Register", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
      },
    });
  },

  getLinkedAwb: () => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return http.get(`apiUser/getListOfAWBsByUser/recents?apiKey=${apikey}`, {});
  },

  getLinkedAwbWebhook: () => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return http.get(`apiUser/getListOfAWBsByUser/webhook?apiKey=${apikey}`, {});
  },

  addAwbToTracking: (payload: { awbNumber: string }) => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return http.post(`apiUser/AddAWBToTrackings?apiKey=${apikey}`, payload, {});
  },
  addAwbToTrackingMicro: (payload: { awbNumber: string }) => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return micro.post(`apiUser/addAWBToTrackings?apiKey=${apikey}`, payload, {});
  },
  addCourierToTrackingMicro: (payload: { courierNumber: string }) => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return micro.post(`apiUser/addAWBToTrackings?apiKey=${apikey}`, payload, {});
  },
  getAwbDetails: (payload: { awbNumber: string }) => {
    const apikey = localStorage.getItem("userApiLupaJwtToken");
    return http.get(
      `/apiUser/getAWBDetails/${payload.awbNumber}?apiKey=${apikey}`
    );
  },
  deleteAwbTracking: (payload: { awbNumber: string }) => {
    return http.get(`/apiUser/deleteAWBTracking/${payload.awbNumber}?`, {
      withCredentials: true,
    });
  },

  getTrackingsHistoriesByAWBNumber: (payload: { awbNumber: string }) => {
    const apikey = localStorage.getItem("lupaJwtToken");
    return http.get(
      `apiUser/GetTrackingsHistoriesByAWBNumber/${payload.awbNumber}?apiKey=${apikey}`
    );
  },

  getAwbDetailsByList: async (payload: { awbNumbers: string[] }) => {
    const awbPromises = payload.awbNumbers.map((awbNumber) => {
      return lupaService.getAwbDetails({ awbNumber: awbNumber });
    });

    return await Promise.allSettled(awbPromises);
  },

  pendingTrackingsAvailableForTheMonth: () => {
    const apikey = localStorage.getItem("lupaJwtToken")
      ? localStorage.getItem("lupaJwtToken")
      : localStorage.getItem("userApiLupaJwtToken");
    return http.get(`apiUser/getTrackingUsage?apiKey=${apikey}`, {});
  },
  getAvailableAirlines: () => {
    return http.get(`apiUser/availableAirlines`, {
      withCredentials: true,
    });
  },
  getUserAirlineMapping: (userId: string | undefined) => {
    return http.get(`user-airline-mapping/` + userId);
  },
  getAvailableAirlinesDs: () => {
    return http.get(`dataCompare/get/latest/dataPref`, {
      withCredentials: true,
    });
  },
  getGocometMilestones: () => {
    return http.get(`apiUser/gocometMilestones`, {
      withCredentials: true,
    });
  },
  getAvailableDataSources: () => {
    return http.get(`apiUser/availableDatasources`, {
      withCredentials: true,
    });
  },
  getDateAirlines: () => {
    return http.get(`apiUser/availableDateformat`, {
      withCredentials: true,
    });
  },
  getStandardMilestones: () => {
    return http.get(`apiUser/standardMilestone`, {
      withCredentials: true,
    });
  },

  adminLogin: (payload: { email: string; password: string }) => {
    return http.post("/auth/login", {
      ...payload,
      clientToken: "2860b141-acd7-4aca-b497-70a20301d898",
      fcmToken: "",
    });
  },

  getSystemStats: () => {
    return http.get("Report/GetSystemStatisticsData", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
      },
    });
  },
  getAllUsers: () => {
    return http.get("Auth/GetUsers", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
      },
    });
  },

  getActiveUsersFromActiveAWBNumber: () => {
    return http.post(
      "Report/GetActiveUsersFromActiveAWBNumbers",
      {
        fromDate: "2023-05-30T08:58:11Z",
        toDate: "2023-06-30T08:58:11Z",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
        },
      }
    );
  },
  getAWBTrackingReport: () => {
    return http.post(
      "/Report/GetAWBTrackingsReport",
      {
        fromDate: "2023-05-30T08:58:11Z",
        toDate: "2023-06-30T08:58:11Z",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
        },
      }
    );
  },

  getUserDetails: (payload: { userId: string }) => {
    return http.get("Auth/GetUser/" + payload.userId, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lupaJwtToken")}`,
      },
    });
  },

  testCall: () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTWFuaWthbmRhIFJ1YmFuIiwidW5pcXVlX25hbWUiOiI1ZTYzNjc1Yy0yZjIzLTQ5MzQtOWMyMC0yNjliZGRiYjMyYTAiLCJzdWIiOiJNYW5pa2FuZGEgUnViYW4iLCJqdGkiOiI3Y2UyMTY3MS1kZmMxLTRkZmYtYWYyNi01NTkxMmFlZTczZDQiLCJlbWFpbCI6Im1hbmlrYW5kYS5ydWJhbithZG1pbkBjb2Rldnlhc2EuY29tIiwidWlkIjoiNWU2MzY3NWMtMmYyMy00OTM0LTljMjAtMjY5YmRkYmIzMmEwIiwiaXAiOiIxNzIuMzEuMzAuMTIxIiwiZXhwIjoxNzE5MjIwMDYwLCJpc3MiOiJMdXBhQ29yZUlkZW50aXR5IiwiYXVkIjoiTHVwYUNvcmVJZGVudGl0eVVzZXIifQ.Iaf58SqWIMYe9keHPIbZEeoEZ3cN4ftefrp4MULL_Hk"
    );
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8FJblOJiGVxAncwf5mthLdT42TXC1vfmfRTbtmyxqK_1c2FuzoqHCQT4g9F4eOuTayCvEBmt5M84aCMODyN69N05iAdMVF5BrTdu9V78tUgZc0H75fllGnTjUNoHo-Ea1fIFtskYzuWaMNPVAT41v_-rTUMm5DbLt4w3tk3UE2gQyDsHjEWJe4EElaXoheKAKR1ugasZtAoJinJMWn1xbIS23qAf2jVfiokU4DQ2M2HcertzcTPUaYdvqs_O_vBcvcaJ7UKlLQUr4wyc1UAjBgYC7JWvBwfEDVejyQ6ZlOiLZjF63hscIyyuC7RfW6iv1D5jJJh6x2GRsmqXwr0NoNALLRNR6ZjO0CYwum8ZbGOw3VZEEd3LGiZ6AZPj4IWwOTDHkS1fMRv0Ww45G96VdHq7imJ4H7r9yxPgC622hpu5fAowFqyvipbTXkVqAKwc3hzK24Q9luNLEU9CmMed-nWbviIQ7C4yOReU8zwWkqeSDl3oOCnaG4maGupVi1qwWXCyz6soc0buxUkyI9bn3Z-vE9pjTJrfHPGyyHMd1-qWMn1XHns3fbEz8-1pjq0xevx4uInkcqDTZsfw_QjmjWmDwR6tSRwJptWs7AJCXOf2xVAk3C0qj6WavItAUU7WwdS6V07Glq7TuqrSG7tOAJypSobL4mTSwNDBKehBxnxngD7WS4e0wqA_RFet5kZl3DPzYY2RIwWKHCjV3rUUzCEg80-PgPJQQsZvabpzT170XEQW5VuFB4gVOi_j28k0wUw85LQaEZ_mTCJSyGZ2TwqO8oUcemMQTy0_jFqZjEHPQnFh98sC47VBYUjpPdl9KxOSg3Js7Qcu94A7oxFeF3d5_Ggh1COVtW8peLYbbw5Wqu8nOwHprz75-nffczapBQ"
    );

    var raw = JSON.stringify({
      fromDate: "2023-05-30",
      toDate: "2023-06-30",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(LUPA_BASE_URL + "api/Report/GetSystemStatisticsData", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  },

  uploadFile: (file: File, apiKey: string) => {
    const formData = new FormData();
    formData.append("file", file);

    console.log(formData.get("file"));

    return fetch(LUPA_BASE_URL + `webhookui/excel/upload?apikey=${apiKey}`, {
      method: "POST",
      body: formData,
    });
  },

  addExcelToTracking: (apiKey: string, name: string, awbNumbers: string[]) => {
    return http.post(
      `${LUPA_BASE_URL}webhookui/add_awb_from_excel?apikey=${apiKey}`,
      {
        awbNumbers: awbNumbers,
        name: name,
      }
    );
  },

  getuploadHistory: () => {
    const apikey = localStorage.getItem("userApiLupaJwtToken");
    return http.get(LUPA_BASE_URL + `webhookui/uploadhistory?apikey=${apikey}`);
  },
  getManualWebhookLog: () => {
    return http.get(LUPA_BASE_URL + "webhookui/manualwebhookLog");
  },

  getScrapDeleteAdd: () => {
    return http.get(LUPA_BASE_URL + "webhookui/scrap/deleteAdd/log");
  },

  getFailedExcelData: (id: number) => {
    return http.get(LUPA_BASE_URL + `webhookui/getfaileddata/${id}`);
  },

  getUserId: (email: string) => {
    return http.post(`${LUPA_BASE_URL}webhookui/addwebhookurl`, {
      email: email,
    });
  },

  updateAWB: (updatedData: UpdatedData) => {
    return http.post(`${LUPA_BASE_URL}webhookui/updateAWB`, updatedData);
  },
};
