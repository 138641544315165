import React, { FC, useState, useEffect } from "react";
import { Container, Card, Form, Button, Alert, Spinner } from "react-bootstrap";
import ClientNavbar from "../../foundation/Navbar";
import axios from "axios";
import envConfig from "../../../utils/envConfig";
const LUPA_BASE_URL = envConfig.url.API_URL;

const UserWebhookMapping = () => {
    const [users, setUsers] = useState<{ id: number; email: string }[]>([]);
    const [milestones, setMilestones] = useState<{ id: number; name: string }[]>([]);
    const [selectedUser, setSelectedUser] = useState<number | null>(null);
    const [selectedMilestones, setSelectedMilestones] = useState<number[]>([]);
    const [version, setVersion] = useState("V2.2");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchUsers();
        fetchMilestones();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetchUserMilestones(selectedUser);
        } else {
            setSelectedMilestones([]);
        }
    }, [selectedUser]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${LUPA_BASE_URL}user/all-users`);
            setUsers(response.data);
        } catch (err) {
            console.error("Error fetching users:", err);
            setError("Failed to load users.");
        }
    };

    const fetchMilestones = async () => {
        try {
            const response = await axios.get(`${LUPA_BASE_URL}user/get-milestone`);
            setMilestones(response.data);
        } catch (err) {
            console.error("Error fetching milestones:", err);
            setError("Failed to load milestones.");
        }
    };

    const fetchUserMilestones = async (userId: number) => {
        try {
            setLoading(true);
            const response = await axios.get(`${LUPA_BASE_URL}user/get-assigned-milestones/${userId}`);
            setSelectedMilestones(response.data.map((milestone: { milestoneId: number }) => milestone.milestoneId));
        } catch (err) {
            console.error("Error fetching user milestones:", err);
            setError("Failed to load assigned milestones.");
        } finally {
            setLoading(false);
        }
    };

    const handleMilestoneChange = (milestoneId: number) => {
        setSelectedMilestones((prev) =>
            prev.includes(milestoneId)
                ? prev.filter((id) => id !== milestoneId)
                : [...prev, milestoneId]
        );
    };

    const handleSave = async () => {
        if (!selectedUser) {
            setError("Please select a user.");
            return;
        }

        setLoading(true);
        setError(null);
        setMessage(null);

        // Identify the IDs for "Trans Shipment Arrival" and "Trans Shipment Departure"
        const transShipmentMilestones = milestones
            .filter(milestone => milestone.name === "Trans Shipment Arrival" || milestone.name === "Trans Shipment Departure")
            .map(milestone => milestone.id);

        // Ensure these IDs are included in the selectedMilestones array
        const milestoneIds = Array.from(new Set([...selectedMilestones, ...transShipmentMilestones]));

        try {
            await axios.post(`${LUPA_BASE_URL}user/assign-milestone`, {
                userId: selectedUser,
                milestoneIds: milestoneIds,
            });

            setMessage("Milestone mappings saved successfully!");
        } catch (err) {
            console.error("Error saving mappings:", err);
            setError("Failed to save mappings.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* <ClientNavbar /> */}
            <Container className="mt-4">
                <Card className="p-4 shadow-sm">
                    <h4 className="mb-3">Webhook Settings</h4>

                    {error && <Alert variant="danger">{error}</Alert>}
                    {message && <Alert variant="success">{message}</Alert>}

                    {/* User Selection */}
                    <Form.Group className="mb-3">
                        <Form.Label>Select User</Form.Label>
                        <Form.Select value={selectedUser || ""} onChange={(e) => setSelectedUser(Number(e.target.value))}>
                            <option value="" disabled>Select a user</option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.email}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>


                    {/* Milestone Selection */}
                    <Form.Group className="mb-3">
                        <Form.Label>Package Status</Form.Label>
                        {loading ? (
                            <div className="text-center">
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <div className="d-flex flex-wrap">
                                {milestones.map((milestone) => {
                                    const isTransShipment = milestone.name === "Trans Shipment Arrival" || milestone.name === "Trans Shipment Departure";
                                    return (
                                        <div key={milestone.id} className="me-3">
                                            <Form.Check
                                                type="checkbox"
                                                id={`milestone-${milestone.id}`}
                                                label={milestone.name}
                                                checked={isTransShipment || selectedMilestones.includes(milestone.id)}
                                                onChange={() => !isTransShipment && handleMilestoneChange(milestone.id)}
                                                disabled={isTransShipment}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Form.Group>

                    {/* Save Button */}
                    <Button variant="primary" onClick={handleSave} disabled={loading}>
                        {loading ? <Spinner as="span" animation="border" size="sm" /> : "Save"}
                    </Button>
                </Card>
            </Container>
        </>
    );
};

export default UserWebhookMapping;
