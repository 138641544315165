import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
  Badge,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuth";
import { lupaService } from "../../../services/lupa";
import ClientNavbar from "../../foundation/Navbar";
import {
  AirlineDefault,
  AirlineMilestone,
  AvailableAirline,
  StandardMilestoneDetails,
} from "./Airline.interface";
import TableRows from "./TableRows";
import { toastTypes } from "../../../utils/toastTypes";
import WebhookUiService from "../../../services/webhook-ui";
import Scraping from "../clientadmin/Scraping";
import { toast } from "react-toastify";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { faLadderWater } from "@fortawesome/free-solid-svg-icons";

interface RowData {
  dataSource: string;
  sampleDateFormat: string;
  dateFormat: string;
  dateFormatCode: number;

  id: number;
  airlineCode: string | undefined | null;
  milestoneName: string;
  isStandard: boolean;
  standardMilestoneId: number | null;
  isRemark: boolean;
  type: "DELETED" | "ADDED" | "UPDATED" | "NONE";
}
interface LoaderProps {
  loading: boolean;
  children: ReactNode;
}
// Define types
interface DataSource {
  id: number;
  name: string;
  isActive: boolean;
  deleted: boolean;
}

interface DateFormat {
  id: number;
  dateFormatCode: string;
}

interface Preference {
  preference: number;
  dataSourceId: string;
  dateFormatTypesId: string;
}
const Loader = ({ loading, children }: LoaderProps) => {
  return (
    <>
      {loading ? (
        <div className="position-relative">
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-white opacity-50"
            style={{ backdropFilter: "blur(10px)" }}
          />
          <Spinner
            animation="border"
            role="status"
            className="position-fixed top-50 start-50"
            style={{ zIndex: 9999 }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div className="spinner-overlay">{children}</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
function AirlineInfo() {
  const handleAirlineTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAirlineType(event.target.value);
  };
  const handleWorkflowChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setWorkflow(event.target.value);
  };
  const [airlineType, setAirlineType] = useState<string>("");
  const [workflow, setWorkflow] = useState<string>("OLD");
  const navigate = useNavigate();
  const [airlineData, setAirlineData] = useState<AvailableAirline[]>([]);
  const [editAirlineData, setEditAirlineData] = useState<AvailableAirline[]>(
    []
  );
  const [airlineMilestone, setAirlineMilestone] = useState<AirlineMilestone[]>(
    []
  );
  const [airlineStandardMilestoneData, setAirlineStandardMilestoneData] =
    useState<StandardMilestoneDetails[]>([]);

  const [isRemarks, setIsRemarks] = useState();

  const [isScrapingExpanded, setIsScrapingExpanded] = useState(true);
  const [isParcelExpanded, setIsParcelExpanded] = useState(false);

  const toggleScraping = () => {
    setIsScrapingExpanded(!isScrapingExpanded);
  };

  const toggleParcel = () => {
    setIsParcelExpanded(!isParcelExpanded);
  };

  const [airlineDateFormat, setAirlineDateFormat] = useState([]);
  const [gocometMilestone, setGocometMilestone] = useState<any[]>([]);
  const [airlineDataSource, setairlineDataSource] = useState<any[]>([]);
  const [isActive, setIsActive] = useState(false); // Default isInactive

  const [updateData, setupdateData] = useState<any[]>([]);
  const [modalType, setModalType] = useState<string>("");
  const [show, setShow] = useState(false);
  const { setLupaJwtToken, setUserApiLupaJwtToken } = useAuthContext();
  const [rowsData, setRowsData] = useState<RowData[]>([]);
  const [rowsData1, setRowsData1] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState<any[]>([]);
  const [error, setError] = useState<string>("");

  // const [isStandard, setIsStandard] = useState<boolean>(false);
  // const [standardMilestone, setStandardMilestone] = useState();
  const [airlineName, setAirlineName] = useState<string | undefined>();
  const [airlineCode, setAirlineCode] = useState<string | undefined | null>();
  const [dateFormat, setDateFormat] = useState<string | undefined>();
  const [addAirlineCode, setAddAirlineCode] = useState<
    string | null | undefined
  >();
  const [editAirlineCode, setEditAirlineCode] = useState<
    string | null | undefined
  >();
  const [defaultAirlineData, setDefaultAirlineData] = useState<AirlineDefault>({
    airlineCode: "",
    airlineName: "",
    airlineType: "",
    dataSource: "",
    dateFormatTypesId: "",
    isRemark: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [shownew, setShownew] = useState(false);


  const handleClose = () => {
    setShownew(false);
    setShow(false);
    setAirlineCode(undefined);
  };
  const handleShow = () => setShownew(true);

  const filteredAirlines = airlineData.filter((airline) =>
    Object.values(airline).some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchQuery.toLowerCase());
      }
      return false;
    })
  );
  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };
  const isUpdateButtonDisabled = () => {
    return (
      (airlineName === defaultAirlineData.airlineName &&
        airlineCode === defaultAirlineData.airlineCode) ||
      // dataSource === defaultAirlineData.dataSource &&
      // dateFormat === defaultAirlineData.dateFormatTypesId
      !airlineName ||
      !airlineCode ||
      // !dataSource ||
      // !dateFormat ||
      airlineCode?.length !== 3 ||
      isNaN(Number(airlineCode))
    );
  };
  const [dataSource, setDataSource] = useState<string | undefined>();

  function handleAirlineName(event: React.ChangeEvent<HTMLInputElement>) {
    setAirlineName(event.target.value);
  }
  function handleAirlineCode(event: React.ChangeEvent<HTMLInputElement>) {
    setAirlineCode(event.target.value);
  }
  const handleDateCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDateFormat(event.target.value);
  };
  async function handleDataSource(event: React.ChangeEvent<HTMLSelectElement>) {
    setDataSource(event.target.value);
    const res = await lupaService.getPendingAWB(airlineCode);
    if (
      // modalType === "editAirline" &&
      res.length > 0 &&
      event.target.value !== "inactive" &&
      dataSource !== event.target.value
    ) {
      setairlineDataSource(res);

      const updatedDataSource = res.map((item: { awbNumber: any }) => ({
        awbNumber: item.awbNumber,
        dataSource: event.target.value.toUpperCase(),
      }));

      console.log("updatedDataSource ==>", updatedDataSource);
      setupdateData(updatedDataSource);
      handleShow();
    }
  }
  const [dataSourcePreferences, setDataSourcePreferences] = useState<
    Preference[]
  >([{ preference: 1, dataSourceId: "", dateFormatTypesId: "" }]);

  useEffect(() => {
    const fetchedDataSources: DataSource[] = [];
    setDataSources(fetchedDataSources);
  }, []);
  useEffect(() => {
    const initialPreferences: Preference[] = [];

    // dataSources.forEach((source, index) => {
    //   initialPreferences.push({
    //     preference: index + 1,
    //     dataSourceId: source.id.toString(),
    //     dateFormatTypesId: "",
    //   });
    // });

    setDataSourcePreferences(initialPreferences);
  }, [dataSources]);
  const addPreferenceRow = () => {
    setDataSourcePreferences([
      ...dataSourcePreferences,
      {
        dataSourceId: "",
        dateFormatTypesId: "",
        preference: dataSourcePreferences.length + 1,
      },
    ]);
  };

  const deletePreferenceRow = (index: number) => {
    if (dataSourcePreferences.length > 1) {
      const updatedPreferences = dataSourcePreferences.filter(
        (_, i) => i !== index
      );
      // Update preferences to maintain correct order
      const reorderedPreferences = updatedPreferences.map((pref, idx) => ({
        ...pref,
        preference: idx + 1,
      }));
      setDataSourcePreferences(reorderedPreferences);
    }
  };
  const handlePreferenceChange = (
    index: number,
    field: keyof Preference,
    value: string | number
  ) => {
    const newPreferences = [...dataSourcePreferences];
    const updatedPreference: Preference = {
      ...newPreferences[index],
      [field]: value,
    };

    if (field === "dataSourceId") {
      const duplicate = newPreferences.some(
        (pref, i) => i !== index && pref.dataSourceId == value
      );
      if (duplicate) {
        setError("Data Source cannot be selected more than once.");
        return;
      }
    }
    if (field === "preference") {
      const duplicatePreference = newPreferences.some(
        (pref, i) => i !== index && pref.preference == value
      );
      if (duplicatePreference) {
        setError("Preference order must be unique.");
        return;
      }
    }

    newPreferences[index] = updatedPreference;
    setDataSourcePreferences(newPreferences);
    setShow(true);
    setError("");
  };

  const toggleActiveStatus = (checked: boolean) => {
    setIsActive(checked);
  };


  async function updatePendingAWB() {
    setLoader(true);
    const res = await lupaService.changeDataSourcebulk(updateData);
    toast.success("Processed successfully");
    // handleUpdateAirline("editAirline");
    setLoader(false);

    handleClose();
  }

  const [data, setData] = useState<Record<"scraping" | "parcel", RowData[]>>({
    scraping: [],
    parcel: [],
  });

  useEffect(() => {
    const scrapingData = rowsData.filter(
      (row) => row.dataSource === "SCRAPING"
    );
    const parcelData = rowsData.filter((row) => row.dataSource === "PARCEL");
    setData({ scraping: scrapingData, parcel: parcelData });
  }, [rowsData]);

  const addTableRows = (type: string, dataSource: string) => {
    const rowsInput: RowData = {
      airlineCode: type === "add" ? addAirlineCode : editAirlineCode,
      id: crypto.getRandomValues(new Uint32Array(1))[0],
      milestoneName: "",
      isStandard: false,
      standardMilestoneId: null,
      type: "ADDED",
      dataSource: dataSource,
      sampleDateFormat: "",
      dateFormat: "",
      dateFormatCode: 0,
      isRemark: false
    };
    // setRowsData((prevRowsData) => [...prevRowsData, rowsInput]);
    setData((prevData) => {
      return rowsInput.dataSource === "SCRAPING"
        ? { ...prevData, scraping: [...prevData.scraping, rowsInput] }
        : { ...prevData, parcel: [...prevData.parcel, rowsInput] };
    });
  };

  const deleteTableRows = (index: number, dataSource: string) => {
    console.log("deleteTableRows", index, dataSource);
    const ds: "scraping" | "parcel" = dataSource.toLowerCase() as
      | "scraping"
      | "parcel";
    setData((prevData) => {
      const updatedData = { ...prevData };
      const rows = updatedData[ds]
        .map((row: RowData, i: number) => {
          if (i === index) {
            if (row.type === "ADDED") {
              return null;
            }
            return { ...row, type: "DELETED" };
          }
          return row;
        })
        .filter(Boolean) as RowData[];

      return { ...updatedData, [dataSource.toLowerCase()]: rows };
    });
  };

  const handleChange = (
    index: number,
    value: string | number | boolean,
    type: string,
    dataSource: string
  ) => {
    const ds: "scraping" | "parcel" = dataSource.toLowerCase() as
      | "scraping"
      | "parcel";

    setData((prevData) => {
      const updatedData = { ...prevData };
      const updatedRows = updatedData[ds].map((row, i) =>
        i === index
          ? {
            ...row,
            [type]: value,
            type: row.type === "NONE" ? "UPDATED" : row.type,
          }
          : row
      );

      return { ...updatedData, [ds]: updatedRows };
    });
  };

  const fetchAirline = () => {
    lupaService.getAvailableAirlines()?.then((res) => {
      setAirlineData(res.data.availableAirlines);
    });
    lupaService.getStandardMilestones()?.then((res) => {
      setAirlineStandardMilestoneData(res.data.standardMilestones);
    });
    lupaService.getDateAirlines()?.then((res) => {
      setAirlineDateFormat(res.data.dateMapping);
    });
    lupaService.getGocometMilestones()?.then((res) => {
      setGocometMilestone(res.data.availableAirlines);
    });
    lupaService.getAvailableDataSources()?.then((res) => {
      setDataSources(res.data);
    });
  };

  function handleUpdateAirline(type: string) {
    let flag = false;
    let newPreferences: Preference[] = [];
    if (type === "editAirline" && !isActive && airlineCode) {
      // console.log("Active=====>", dataSources);
      const Inactive = dataSources.find((source) => source.name === "inactive");

      if (!Inactive) {
        console.error("Inactive data source not found!");
        return;
      }

      // Define the new preferences and set the state
      newPreferences = [{ preference: 1, dataSourceId: Inactive.id, dateFormatTypesId: "100" }];
      setDataSourcePreferences(newPreferences);
      flag = true;
      // Use `newPreferences` immediately if needed
      // console.log("New Preferences:", newPreferences);

      if (airlineCode) {
        // @ts-ignore
        lupaService!
          .IsActiveAirline(airlineCode)
          .then((res) => {
            if (res?.status === 200) {
              console.log("Airline is active");
            } else {
              console.error("Unexpected response status:", res?.status ?? "undefined");
            }
          })
          .catch((error) => {
            console.error("Error checking if airline is active:", error);
          });
      } else {
        console.error("airlineCode is undefined");
      }
    } else if (isActive && dataSourcePreferences[0].dataSourceId == "4") {
      // console.log("newPreferences=====>", newPreferences);

      // console.log("dataSourcePreferences=====>", dataSourcePreferences);
      return toastTypes.error("Please Select the source");
    }
    // console.log("dataSourcePreferences=====>", dataSourcePreferences, flag);

    if (!airlineName || !airlineCode)
      return toastTypes.error("Please fill all the fields");
    const functionName = type === "editAirline" ? "updateAirline" : "addAirline";

    lupaService[functionName]({
      name: airlineName,
      airlineCode: airlineCode,
      airlineType: airlineType,
      workflow: workflow,
      dataSourcePreferences: flag ? newPreferences : dataSourcePreferences,
    })
      ?.then((response: any) => {
        response
          .json()
          .then((data: any) => {
            if (response.status === 200 || response.status === 201) {
              if (data.message) {
                setShow(false);
                setAirlineCode(undefined);
                setDateFormat(undefined);
                setAirlineName(undefined);
                setDataSource(undefined);
                setAirlineType("");
                setWorkflow("OLD");
                setDefaultAirlineData({
                  airlineCode: "",
                  airlineName: "",
                  dataSource: "",
                  airlineType: "",
                  dateFormatTypesId: "",
                  isRemark: false,
                });
                fetchAirline();
                return toastTypes.success(data.message);
              }
            }
            console.log(data);
            if (response.status === 400)
              return toastTypes.error("Airline Already Exists");
          })
          .catch((error: any) => {
            console.log({ error });
            toastTypes.error("An error occurred while creating the airline");
          });
      })
      .catch((error: any) => {
        console.log(error.result);
      });
  }

  type Milestone = {
    id: number;
    airlineCode: string;
    milestoneName: string;
    isStandard: boolean;
    dataSource: string;
    type: string;
    standardMilestoneId: number | null;
    sampleDateFormat?: string;
    dateFormat?: string;
    dateFormatCode?: number;
    isRemark: boolean;
  };

  type OutputType = {
    mileStoneData: Milestone[];
  };

  function transformData(input: any): any {
    const { scraping, parcel } = input;

    const mileStoneData: Milestone[] = [...scraping, ...parcel];

    return mileStoneData;
  }
  function handleUpdateMilestone() {
    setRowsData(transformData(data));
    const updatedData = transformData(data);
    lupaService
      .updateAirlineMilestone(updatedData)
      ?.then((response: any) => {
        response.json().then((data: any) => {
          console.log(response.status);
          if (response.status === 201) {
            if (data.isSuccess) {
              setShow(false);
              fetchAirline();
              return toastTypes.success(data.message);
            }
          }
          if (response.status === 400) return toastTypes.error(data.message);
        });
      })
      .catch((error: any) => {
        console.log(error.result);
      });
  }

  async function handleModal(
    data: any,
    type: string,
    carrierCode?: string | null
  ) {
    setShow(true);
    setRowsData([]);
    setModalType(type);
    console.log({ airlineData });
    console.log({ airlineCode });
    const airline = airlineData.find((airline) => {
      console.log(airline);
      return airline.airlineCode == data.airlineCode;
    });
    console.log({ airline });
    // console.log({ airline });
    if (airline && airline.dataSource != "inactive") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    if (type === "editAirline" || type === "addAirline") {
      if (type === "addAirline") {
        setAirlineCode(undefined);
        setAirlineName(undefined);
        setDataSource("");
        setDefaultAirlineData({
          airlineCode: "",
          airlineName: "",
          airlineType: "",
          dataSource: "",
          dateFormatTypesId: "",
          isRemark: false,
        });
      } else {
        setAirlineCode(data.airlineCode);
        setAirlineName(data.name);
        setAirlineType(data.airlineType);
        setWorkflow(data.workflow);
        setDataSource(data.dataSource);
        setDateFormat(data.dateFormatTypesId);
        setIsRemarks(data.isRemark);
        setEditAirlineData(data);
        setDefaultAirlineData({
          airlineCode: data.airlineCode,
          airlineName: data.name,
          airlineType: data.airlineType,
          dataSource: data.dataSource,
          dateFormatTypesId: data.dateFormatTypesId,
          isRemark: data.isRemark,
        });
        //
        const preferences: Preference[] = data.airlineDataSourcePrefs.map(
          (pref: {
            preference: any;
            dataSourceId: any;
            dataFormatTypesId: any;
          }) => ({
            preference: pref.preference,
            dataSourceId: pref.dataSourceId,
            dateFormatTypesId: pref.dataFormatTypesId,
          })
        );
        setDataSourcePreferences(preferences);
      }
    } else if (type === "editMilestone" || type === "viewMilestone") {
      setRowsData(
        data?.map((airlineMilestone: AirlineMilestone, index: Number) => {
          return {
            id: airlineMilestone.id,
            airlineCode: airlineMilestone.airlineCode,
            milestoneName: airlineMilestone.name,
            isStandard: airlineMilestone.isStandard,
            dataSource: airlineMilestone.dataSource,
            type: "NONE",
            // standardMilestone: airlineMilestone.standardMilestoneDetails?.name,
            standardMilestoneId: airlineMilestone.standardMilestoneDetails
              ? airlineMilestone.standardMilestoneDetails?.id
              : null,
            isRemark: airlineMilestone.isRemark
          };
        })
      );
      console.log({ data });
      setAirlineMilestone(data);
      setEditAirlineCode(carrierCode);
    } else if (type === "viewdateFormat") {
      setRowsData(airlineDateFormat);
    } else if (type === "mapdateFormat") {
      setRowsData(airlineDateFormat);
      setRowsData1(data);
    } else {
      console.log(rowsData);
      setAddAirlineCode(carrierCode ?? null);
    }
  }

  const [showAwbModal, setShowAwbModal] = useState(false);
  const [awbNumber, setAwbNumber] = useState("");
  const [awbError, setAwbError] = useState("");
  const [liveData, setLiveData] = useState<any>();
  const [showLiveDataModal, setShowLiveDataModal] = useState(false);
  const [loader, setLoader] = useState(false);

  // Function to handle showing the AWB number modal
  const handleShowAwbModal = () => {
    setShowAwbModal(true);
    setAwbNumber("");
  };

  // Function to handle closing the AWB number modal
  const handleCloseAwbModal = () => {
    setShowAwbModal(false);
    setAwbNumber("");
    setAwbError("");
  };

  // Function to handle changing AWB number input
  const handleAwbNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{3}-\d{8}$/.test(input)) {
      setAwbNumber(input);
      setAwbError("");
    } else {
      setAwbNumber(input);
      setAwbError("Please enter a valid AWB number (e.g., 123-12345678)");
    }
  };
  const handleShowLiveDataModal = () => {
    setShowLiveDataModal(true);
  };

  // Function to handle fetching data based on AWB number
  const handleFetchData = async (id: string) => {
    if (/^\d{3}-\d{8}$/.test(id)) {
      try {
        setLoading(true);
        const response = await WebhookUiService.getLiveData(id);
        const responseData = response || "";
        setLiveData(responseData);
        // handleShowLiveDataModal();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        handleShowLiveDataModal(); // Set loading state to false after fetching data
      }
    }

    handleCloseAwbModal();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevents default form submission behavior
    if (/^\d{3}-\d{8}$/.test(awbNumber)) {
      handleFetchData(awbNumber);
    }
  };

  const handleCloseLiveDataModal = () => {
    setShowLiveDataModal(false);
    setAwbNumber("");
  };

  useEffect(() => {
    fetchAirline();
  }, []);

  return (
    <>
      <Loader loading={loader}>
        <Modal
          size="lg"
          show={show}
          centered
          onHide={() => {
            setShow(false);
            setAirlineCode(undefined);
            setAirlineName(undefined);
            setAddAirlineCode(undefined);
            setEditAirlineCode(undefined);
            setDataSource(undefined);
            setDefaultAirlineData({
              airlineCode: "",
              airlineName: "",
              airlineType: "",
              dataSource: "",
              dateFormatTypesId: "",
              isRemark: false,
            });
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {modalType === "addMilestone" && "Add Airline Milestone"}
              {modalType === "editAirline" && "Edit Airline"}
              {modalType === "editMilestone" &&
                `${"Edit MileStone - (" + airlineMilestone[0]?.airlineName + ")"
                }`}
              {modalType === "viewMilestone" &&
                `${"View MileStone - (" + airlineMilestone[0]?.airlineName + ")"
                }`}
              {modalType === "viewdateFormat" &&
                "View Map Date Format with Logic code"}
              {modalType === "mapdateFormat" &&
                "View Map Date Format with Logic code"}
              {modalType === "viewStandardMilestone" && "Standard Milestone"}
              {modalType === "viewGocometMilestone" && "Gocommet Milestone"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalType === "addMilestone" && (
              <>
                <div className="container mt-5">
                  {modalType === "addMilestone" && (
                    <>
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingScraping">
                            <button
                              className={`accordion-button ${isScrapingExpanded ? "" : "collapsed"
                                }`}
                              type="button"
                              onClick={toggleScraping}
                              aria-expanded={isScrapingExpanded}
                              aria-controls="collapseScraping"
                            >
                              Scraping
                            </button>
                          </h2>
                          <div
                            id="collapseScraping"
                            className={`accordion-collapse collapse ${isScrapingExpanded ? "show" : ""
                              }`}
                            aria-labelledby="headingScraping"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <button
                                className="btn btn-outline-primary float-end mb-3"
                                onClick={() => addTableRows("add", "SCRAPING")}
                              >
                                Add Row
                              </button>
                              <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                className="mt-3"
                              >
                                <thead>
                                  <tr>
                                    <th className="text-center">
                                      Airline Milestone
                                    </th>
                                    <th className="text-center">
                                      Standard (Y/N)
                                    </th>
                                    <th className="text-center">
                                      Standard Milestone
                                    </th>
                                    <th className="text-center">
                                      Remarks (Y/N)
                                    </th>
                                    <th className="text-center">Delete</th>
                                  </tr>
                                </thead>
                                <TableRows
                                  rowsData={data.scraping}
                                  deleteTableRows={deleteTableRows}
                                  handleChange={handleChange}
                                />
                              </Table>
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingParcel">
                            <button
                              className={`accordion-button ${isParcelExpanded ? "" : "collapsed"
                                }`}
                              type="button"
                              onClick={toggleParcel}
                              aria-expanded={isParcelExpanded}
                              aria-controls="collapseParcel"
                            >
                              Parcel
                            </button>
                          </h2>
                          <div
                            id="collapseParcel"
                            className={`accordion-collapse collapse ${isParcelExpanded ? "show" : ""
                              }`}
                            aria-labelledby="headingParcel"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <button
                                className="btn btn-outline-primary float-end mb-3"
                                onClick={() => addTableRows("add", "PARCEL")}
                              >
                                Add Row
                              </button>
                              <Table
                                striped
                                bordered
                                hover
                                size="sm"
                                className="mt-3"
                              >
                                <thead>
                                  <tr>
                                    <th className="text-center">
                                      Airline Milestone
                                    </th>
                                    <th className="text-center">
                                      Standard (Y/N)
                                    </th>
                                    <th className="text-center">
                                      Standard Milestone
                                    </th>
                                    <th className="text-center">
                                      Remarks (Y/N)
                                    </th>
                                    <th className="text-center">Delete</th>
                                  </tr>
                                </thead>
                                <TableRows
                                  rowsData={data.parcel}
                                  deleteTableRows={deleteTableRows}
                                  handleChange={handleChange}
                                />
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Button
                        variant="primary"
                        className="float-end mt-3"
                        onClick={handleUpdateMilestone}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}

            {modalType === "editMilestone" && (
              <>
                <div className="container mt-5">
                  <div className="accordion" id="accordionExample">
                    {data.scraping.length >= 0 && (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingScraping">
                          <button
                            className={`accordion-button ${isScrapingExpanded ? "" : "collapsed"
                              }`}
                            type="button"
                            onClick={toggleScraping}
                            aria-expanded={isScrapingExpanded}
                            aria-controls="collapseScraping"
                          >
                            Scraping
                          </button>
                        </h2>
                        <div
                          id="collapseScraping"
                          className={`accordion-collapse collapse ${isScrapingExpanded ? "show" : ""
                            }`}
                          aria-labelledby="headingScraping"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <button
                              className="btn btn-outline-primary float-end mb-3"
                              onClick={() => addTableRows("edit", "SCRAPING")}
                            >
                              Add Row
                            </button>
                            <Table
                              striped
                              bordered
                              hover
                              size="sm"
                              className="mt-3"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center">
                                    Airline Milestone
                                  </th>
                                  <th className="text-center">
                                    Standard (Y/N)
                                  </th>
                                  <th className="text-center">
                                    Standard Milestone
                                  </th>
                                  <th className="text-center">
                                    Remark
                                  </th>
                                  <th className="text-center">Delete</th>
                                </tr>
                              </thead>
                              <TableRows
                                rowsData={data.scraping}
                                deleteTableRows={deleteTableRows}
                                handleChange={handleChange}
                              />
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.parcel.length >= 0 && (
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingParcel">
                          <button
                            className={`accordion-button ${isParcelExpanded ? "" : "collapsed"
                              }`}
                            type="button"
                            onClick={toggleParcel}
                            aria-expanded={isParcelExpanded}
                            aria-controls="collapseParcel"
                          >
                            Parcel
                          </button>
                        </h2>
                        <div
                          id="collapseParcel"
                          className={`accordion-collapse collapse ${isParcelExpanded ? "show" : ""
                            }`}
                          aria-labelledby="headingParcel"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <button
                              className="btn btn-outline-primary float-end mb-3"
                              onClick={() => addTableRows("edit", "PARCEL")}
                            >
                              Add Row
                            </button>
                            <Table
                              striped
                              bordered
                              hover
                              size="sm"
                              className="mt-3"
                            >
                              <thead>
                                <tr>
                                  <th className="text-center">
                                    Airline Milestone
                                  </th>
                                  <th className="text-center">
                                    Standard (Y/N)
                                  </th>
                                  <th className="text-center">
                                    Standard Milestone
                                  </th>
                                  <th className="text-center">
                                    Remark
                                  </th>
                                  <th className="text-center">Delete</th>
                                </tr>
                              </thead>
                              <TableRows
                                rowsData={data.parcel}
                                deleteTableRows={deleteTableRows}
                                handleChange={handleChange}
                              />
                            </Table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  variant="primary"
                  className="float-end"
                  onClick={() => handleUpdateMilestone()}
                >
                  Update
                </Button>
              </>
            )}

            {modalType === "viewMilestone" && (
              <>
                {["SCRAPING", "PARCEL"].map((dataSource) => (
                  <div key={dataSource}>
                    <h3 className="text-center">{dataSource}</h3>
                    <Table striped bordered hover size="sm">
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Is Standard Milestone</th>
                          <th className="text-center">
                            Standard Milestone Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {airlineMilestone
                          .filter(
                            (milestone) => milestone.dataSource === dataSource
                          )
                          .map((milestone, index) => (
                            <tr key={milestone.id}>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">{milestone.name}</td>
                              <td className="text-center">
                                {milestone.isStandard ? (
                                  <i className="bi bi-check-circle-fill text-success"></i>
                                ) : (
                                  <i className="bi bi-x-circle-fill text-danger"></i>
                                )}
                              </td>
                              <td className="text-center">
                                {milestone.standardMilestoneDetails
                                  ? milestone.standardMilestoneDetails.name
                                  : "No Standard Milestone"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </>
            )}

            {modalType === "viewGocometMilestone" && (
              <>
                <div key={dataSource}>
                  <h3 className="text-center">{""}</h3>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Is Standard Milestone</th>
                        <th className="text-center">Standard Milestone Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gocometMilestone.map((milestone, index) => (
                        <tr key={""}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{milestone.name}</td>
                          <td className="text-center">
                            {milestone.isActive ? (
                              <i className="bi bi-check-circle-fill text-success"></i>
                            ) : (
                              <i className="bi bi-x-circle-fill text-danger"></i>
                            )}
                          </td>
                          <td className="text-center">
                            {milestone.standardMilestoneDetails
                              ? milestone.standardMilestoneDetails.name
                              : "No Standard Milestone"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            {modalType === "viewStandardMilestone" && (
              <div key={dataSource}>
                <h3 className="text-center">{""}</h3>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Is Active</th>
                      <th className="text-center">Order</th>
                      <th className="text-center">Transshipment Name</th>
                      {/* <th className="text-center">Updated At</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {airlineStandardMilestoneData.map((milestone, index) => (
                      <tr key={index}>
                        <td className="text-center">{milestone.id}</td>
                        <td className="text-center">{milestone.name}</td>
                        <td className="text-center">
                          {milestone.isActive ? (
                            <i className="bi bi-check-circle-fill text-success"></i>
                          ) : (
                            <i className="bi bi-x-circle-fill text-danger"></i>
                          )}
                        </td>
                        <td className="text-center">{milestone.order}</td>
                        <td className="text-center">{milestone.transName}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}

            {(modalType === "editAirline" || modalType === "addAirline") && (
              <>
                <Form>
                  <Form.Group className="mb-3" controlId="airlineName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={airlineName}
                      onChange={handleAirlineName}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="airlineCode">
                    <Form.Label>Airline Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Airline Code"
                      value={airlineCode ?? ""}
                      onChange={handleAirlineCode}
                      disabled={modalType === "editAirline"}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="airlineType">
                    <Form.Label>Airline Type</Form.Label>
                    <Form.Select
                      aria-label="Select Airline Type"
                      value={airlineType}
                      onChange={handleAirlineTypeChange}
                    >
                      <option value="">Select Airline Type</option>
                      <option value="cargo">Cargo</option>
                      <option value="courier">Courier</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="airlineType">
                    <Form.Label> WorkFlow </Form.Label>
                    <Form.Select
                      aria-label="Select  Workflow"
                      value={workflow}
                      onChange={handleWorkflowChange}
                    >
                      <option value="">Select Workflow</option>
                      <option value="OLD">OLD</option>
                      <option value="NEW">NEW</option>
                    </Form.Select>
                  </Form.Group>


                  {modalType === "editAirline" && (
                    <Form.Group className="mb-3" controlId="airlineStatus">
                      <Form.Label>Status</Form.Label>
                      <Form.Check
                        type="switch"
                        id="airline-status-switch"
                        label={isActive ? "Active" : "Inactive"}
                        checked={isActive}
                        onChange={(e) => toggleActiveStatus(e.target.checked)}
                      />
                    </Form.Group>
                  )}


                  {error && <Alert variant="danger">{error}</Alert>}

                  <div className="accordion-body">
                    <Button
                      variant="primary"
                      className="float-end mb-3"
                      onClick={addPreferenceRow}
                    >
                      Add Row
                    </Button>
                    <br />
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Order</th>
                          <th>Data Source</th>
                          <th>Date Format</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSourcePreferences.map((pref, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center justify-content-center mt-2">
                                <Badge bg="primary">{pref.preference}</Badge>
                              </div>
                            </td>

                            <td>
                              <Form.Select
                                aria-label="Select Data Source"
                                value={pref.dataSourceId}
                                onChange={(e) =>
                                  handlePreferenceChange(
                                    index,
                                    "dataSourceId",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select a data source</option>
                                {dataSources
                                  .filter((source) => source.name !== "inactive")
                                  .map((source) => (
                                    <option key={source.id} value={source.id}>
                                      {source.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Select
                                aria-label="Select Date Format"
                                value={pref.dateFormatTypesId}
                                onChange={(e) =>
                                  handlePreferenceChange(
                                    index,
                                    "dateFormatTypesId",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Date Code</option>
                                {airlineDateFormat.map((format: any) => (
                                  <option key={format.id} value={format.id}>
                                    {format.dateFormatCode} -{" "}
                                    {format.dateFormat}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => deletePreferenceRow(index)}
                                disabled={dataSourcePreferences.length === 1}
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Form>
                <Button
                  variant="success"
                  className="float-end"
                  onClick={() => handleUpdateAirline(modalType)}
                  disabled={false}
                >
                  {modalType === "editAirline" ? "Update" : "Add"}
                </Button>
              </>
            )}

            {modalType === "viewdateFormat" && (
              <Table striped bordered hover size="sm" className="mt-5">
                <thead>
                  <tr>
                    <th className="text-center">Dateformat</th>
                    <th className="text-center">Map </th>
                    <th className="text-center">Sample Dateformat</th>
                  </tr>
                </thead>
                <tbody>
                  {rowsData.map((data, index) => {
                    return (
                      <tr>
                        <td className="text-center"> {data?.dateFormat} </td>
                        <td className="text-center"> {data.id} </td>
                        <td className="text-center">
                          {" "}
                          {data?.sampleDateFormat}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {modalType === "mapdateFormat" && (
              <Table striped bordered hover size="sm" className="mt-5">
                <thead>
                  <tr>
                    <th className="text-center">Dateformat</th>
                    <th className="text-center">Map </th>
                    <th className="text-center">Sample Dateformat</th>
                  </tr>
                </thead>
                <tbody>
                  {rowsData.map((data, index) => {
                    return rowsData1.dateFormatTypesId ===
                      data?.dateFormatCode ? (
                      <tr key={index}>
                        <td className="text-center">{data?.dateFormat}</td>
                        <td className="text-center">{data.dateFormatCode}</td>
                        <td className="text-center">
                          {data?.sampleDateFormat}
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </Table>
            )}
          </Modal.Body>
        </Modal>

        <Modal show={shownew} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Tracking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <h2>New Data Source</h2>
              <p className="lead">
                The numbers below have been updated based on the selected data
                source:
              </p>
              <Form.Select
                aria-label="Select Date Format"
                value={dataSource}
                onChange={(e) => handleDataSource(e)}
              >
                <option value="">Select a data source</option>
                {dataSources.map((source) => (
                  <option key={source.id} value={source.name}>
                    {source.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <h3 className="text-center">Tracking Details</h3>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th className="text-center">AWB Number</th>
                    <th className="text-center">Old Data Source</th>
                    <th className="text-center">New Data Source</th>
                    <th className="text-center">Tracking Status</th>
                  </tr>
                </thead>
                <tbody>
                  {airlineDataSource.map((data, index) => (
                    <tr key={index}>
                      <td className="text-center">{data.awbNumber}</td>
                      <td className="text-center">
                        <>
                          {data.isGocomet && (
                            <span className="text-success">Gocomet</span>
                          )}
                          {data.isParcel && (
                            <span className="text-success">Parcel</span>
                          )}
                          {data.isScraping && (
                            <span className="text-success">Scraping</span>
                          )}
                        </>
                      </td>
                      <td className="text-center">
                        <span className="text-success">
                          {(dataSource ?? "").charAt(0).toUpperCase() +
                            (dataSource ?? "").slice(1)}
                        </span>
                      </td>

                      <td className="text-center">{data.trackingStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" onClick={updatePendingAWB}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>

        <ClientNavbar />
        <Container className="py-5">
          <Row className="my-2">
            <Col>
              <h4>Airlines</h4>
            </Col>
            <Col className="text-end">
              <div className="d-flex justify-content-end">
                <Button
                  variant="primary"
                  onClick={() => handleModal({}, "addAirline")}
                  className="me-2"
                >
                  Add Airline
                </Button>
                <DropdownButton id="actions-dropdown" title="Actions" variant="primary">
                  <Dropdown.Item onClick={() => navigate("/unsupport-airlines")}>
                    Unsupported Airline
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleModal({}, "viewdateFormat")}>
                    Date Format
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleShowAwbModal}>
                    Live Scraping from Airline
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleModal({}, "viewStandardMilestone")}>
                    View standard Milestone
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleModal({}, "viewGocometMilestone")}>
                    View Gocomet Milestone
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/data-source-changelog")}>
                    Logs
                  </Dropdown.Item>
                </DropdownButton>{""}
                <br />
                {/* AWB Number input modal */}
                <Modal show={showAwbModal} onHide={handleCloseAwbModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Enter AWB Number</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="awbNumberInput">
                        <Form.Label>AWB Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter AWB Number (e.g. 123-12345678)"
                          value={awbNumber}
                          onChange={handleAwbNumberChange}
                          isInvalid={!!awbError}
                        />
                        <Form.Control.Feedback type="invalid">
                          {awbError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="text-center mt-3">
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={!!awbError}
                          >
                            Enter
                          </Button>
                        )}
                      </div>
                    </Form>
                  </Modal.Body>
                </Modal>

                {/* Modal to display live data */}
                <Modal
                  show={showLiveDataModal}
                  onHide={handleCloseLiveDataModal}
                  dialogClassName="modal-xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{`Actual Data`}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <pre>{JSON.stringify(liveData, null, 2)}</pre>
                  </Modal.Body>
                </Modal>

              </div>
            </Col>
          </Row>

          <Row className="justify-content-end">
            <Col className="d-flex justify-item-end mb-1 col-3">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchQueryChange}
                className="form-control-sm"
              />
            </Col>
          </Row>
          <Table striped bordered hover size="sm" className="shadow rounded">
            <thead className="rounded">
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">Name</th>
                <th className="text-center">Airline Code</th>
                <th className="text-center">Standard Milestone</th>
                <th className="text-center">Data source change</th>
                <th className="text-center">Data Source</th>
                <th className="text-center">Milestone</th>
                <th className="text-center">Airline Type</th>
                <th className="text-center">WorkFlow</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredAirlines?.map(
                (airline: AvailableAirline, index: number) => (
                  <tr key={index}>
                    <td className="text-center" valign="middle">
                      {index + 1}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.name}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.airlineCode}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.isStandardMilestone === true &&
                        airline.AirlineMileStone.length > 0 ? (
                        <i className="bi bi-check-circle-fill text-success"></i>
                      ) : (
                        <i className="bi bi-x-circle-fill text-danger"></i>
                      )}
                    </td>
                    <td className="text-center" valign="middle">
                      <Button
                        variant="primary"
                        onClick={() => {
                          setAirlineCode(airline.airlineCode);
                          handleShow();
                        }}
                      >
                        Source Change
                      </Button>{" "}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline.dataSource === "scraping" ? (
                        <Badge bg="primary">Scraping</Badge>
                      ) : airline.dataSource === "gocomet" ? (
                        <Badge bg="secondary">Gocomet</Badge>
                      ) : airline.dataSource === "parcel" ? (
                        <Badge bg="secondary">Parcel</Badge>
                      ) : (
                        <Badge bg="danger">Inactive</Badge>
                      )}
                    </td>

                    <td className="text-center" valign="middle">
                      {airline.isStandardMilestone === true &&
                        airline.AirlineMileStone.length > 0 ? (
                        <>
                          <Button
                            size="sm"
                            onClick={() =>
                              handleModal(
                                airline.AirlineMileStone,
                                "viewMilestone"
                              )
                            }
                          >
                            <i className="bi bi-eye-fill"></i>
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="primary"
                            onClick={() =>
                              handleModal(
                                airline.AirlineMileStone,
                                "editMilestone",
                                airline.airlineCode
                              )
                            }
                          >
                            <i className="bi bi-pencil-square"></i>
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() =>
                            handleModal(
                              airline.AirlineMileStone,
                              "addMilestone",
                              airline.airlineCode
                            )
                          }
                        >
                          <i className="bi bi-plus-circle-fill"></i>
                        </Button>
                      )}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline?.airlineType === "courier" ? (
                        <Badge bg="info">Courier</Badge>
                      ) : airline?.airlineType === "cargo" ? (
                        <Badge bg="warning">Cargo</Badge>
                      ) : (
                        <Badge bg="secondary">-</Badge>
                      )}
                    </td>
                    <td className="text-center" valign="middle">
                      {airline?.workflow}
                    </td>
                    <td className="text-center" valign="middle">
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => handleModal(airline, "editAirline")}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Container>
      </Loader>
    </>
  );
}
export default AirlineInfo;
