import { Badge, Container, Modal } from "react-bootstrap";
import { Button as BootstrapButton } from "react-bootstrap";
import Base from "../../common/Base";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  MRT_Row,
  MRT_FilterFn,
} from "material-react-table";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useQuery } from "react-query";
import { getAWBList } from "../../../services/customer";
import { useMemo, useState } from "react";
import { AWBList } from "../../../models/Common";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConfirmActionPopup from "../../modals/Confirm";
import { hardDeleteAWBDetail } from "../../../services/awbDetails";
import { toastTypes } from "../../../utils/toastTypes";
import WebhookUiService from "../../../services/webhook-ui";
import "../../../../src/popup.css";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { WebhookTrackingDetails } from "../tracking/WebhookTrackingDetails";
import { FormattedDataDisplay } from "../tracking/FormatteddataDisplay";
import { SearchAwbFE } from "../tracking/SearchAwbFE";
import { lupaService } from "../../../services/lupa";
import { toast } from "react-toastify";

type ShowDelete = {
  show: boolean;
  awbNumber: string | undefined;
};

const defaultShowDelete = {
  show: false,
  awbNumber: undefined,
};

function AWBUsage() {
  const { data, isLoading, isError, error, refetch } = useQuery(
    ["customer"],
    () => getAWBList(),
    {
      refetchOnWindowFocus: false,
    }
  );
  console.log(data);

  const [isRefetching, setIsRefetching] = useState(false);
  const [showDelete, setShowDelete] = useState<ShowDelete>(defaultShowDelete);
  const [rowdata, setData] = useState<{ data: AWBList[] }>({ data: [] });
  const navigate = useNavigate();
  const [checkboxState, setCheckboxState] = useState(false);

  // const handleClick = (id: number) => {
  //   navigate(`/billing/${id}`);
  // };

  const handleExportRows = (rows: MRT_Row<AWBList>[]) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => {
      return Object.values({
        name: row.original.user
          .map((user) => user.firstName + " " + user.lastName)
          .join("\n"),
        email: row.original.user.map((user) => user.email).join("\n"),
        awbNumber: row.original.awbNumber,
        airlineCode: row.original.airlineCode,
        createdAt: new Date(row.original.createdDateTime).toLocaleDateString(),
      });
    });
    const tableHeaders = columns
      .map((c) => c.header)
      .slice(0, columns.length - 1);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      margin: { top: 40 },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 60 },
        2: { cellWidth: 30 },
        3: { cellWidth: 15 },
        4: { cellWidth: 30 },
        // etc
      },
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(26);
        doc.setTextColor(40);
        doc.text(`AWBs Count: ${rows.length}`, data.settings.margin.left, 22);

        // Footer
        var str =
          "Page " + (doc as any).internal.getCurrentPageInfo().pageNumber;
        console.log((doc as any).internal.getCurrentPageInfo());
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
    doc.save("AWBList.pdf");
  };
  const handleExportToExcel = (rows: MRT_Row<AWBList>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AWBList");

    XLSX.writeFile(workbook, "AWBList.xlsx");
  };

  const handleDeleteSubmit = (awbNumber: string) => {
    // const inputtedAWBnumber = prompt(
    //   "Please enter the AWB number to confirm deletion"
    // );
    // if (inputtedAWBnumber !== awbNumber) {
    //   toastTypes.error("AWB number does not match");
    //   return;
    // }
    hardDeleteAWBDetail(awbNumber)
      .then(async (data) => {
        console.log(data);
        if (data.data.isSuccess) {
          setIsRefetching(true);
          setShowDelete(defaultShowDelete);
          toastTypes.success("AWB deleted successfully");
          await refetch();
          setIsRefetching(false);
        } else {
          toastTypes.error(data.data.message);
          setShowDelete(defaultShowDelete);
        }
      })
      .catch((error) => {
        console.log(error);
        toastTypes.error(error.message);
      });
  };
  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  //webhook data ui exposure
  const [showDetails, setShowDetails] = useState(false);
  const [selectedAWBDetails, setSelectedAWBDetails] = useState<any[]>([]);
  const [selectedAWBNumber, setSelectedAWBNumber] = useState<string>("");

  const handleShowDetails = async (awbNumber: string) => {
    try {
      const response = await WebhookUiService.getAwbPopupData(awbNumber);
      console.log(response);

      if (!response) {
        throw new Error("No valid data received");
      }

      const formattedDetailsForTracking = [
        {
          trackingStatus: response.trackingStatus || "-",
          awbNumber: response.awbNumber || "-",
          origin: response.origin || "-",
          destination: response.destination || "-",
          milestone: response.milestone || "-",
          etd: response.etd || "-",
          eta: response.eta || "-",
          weight: response.weight || "-",
          pieces: response.pieces || "-",
          flights: response.flightsEtdEta || [],
          events: response.events || [],
          remarks: response.remarks || [],
        },
      ];
      // console.log("****************");
      // console.log(formattedDetailsForTracking);
      // console.log(response);
      // console.log("****************");
      setSelectedAWBDetails(formattedDetailsForTracking);
      setSelectedAWBNumber(awbNumber);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching details: ", error);
      setSelectedAWBDetails([]); // Set an empty array to handle changes
      setSelectedAWBNumber(""); // Reset selected AWB number
      setShowDetails(true);
    }
  };
  const webhookSentFilterFn: MRT_FilterFn<AWBList> = (row, id, filterValue) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const filterBoolean = filterValue === "true";
    return row.original.etdEtaUpdate === filterBoolean;
  };

  const manualwebhookSentFilterFn: MRT_FilterFn<AWBList> = (
    row,
    id,
    filterValue
  ) => {
    if (filterValue === "") {
      return true; // Show all rows when filter value is empty
    }
    const normalizedFilterValue = filterValue.toLowerCase();
    const filterBoolean =
      normalizedFilterValue === "true" ||
      normalizedFilterValue === "t" ||
      normalizedFilterValue === "tr" ||
      normalizedFilterValue === "tru";
    return row.original.hasRemarks === filterBoolean;
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };
  const handleToggle = async (awbNumber: string, newValue: boolean) => {
    try {
      console.log(newValue);
      console.log(awbNumber);
      const inputtedAWBnumber = prompt(
        "Please enter the AWB number to confirm the update"
      );
      if (inputtedAWBnumber !== awbNumber) {
        toastTypes.error("AWB number does not match");
        setCheckboxState(false); // Uncheck the checkbox
        return;
      }

      const response = await WebhookUiService.updateEtdEta(awbNumber);
      console.log(response);
      if (response.data === "Success") {
        setIsRefetching(true);
        toastTypes.success("Update successful");
        // window.location.reload();
        await refetch();
        setIsRefetching(false);
      } else {
        toastTypes.error("Conditions not met");
        newValue = false;
      }
    } catch (error) {
      console.error("Error updating toggle:", error);
      toastTypes.error("An error occurred while updating");
    }
  };
  const getBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "delivered":
        return "success";
      case "in transit":
        return "info";
      case "pending":
        return "warning";
      case "canceled":
        return "danger";
      default:
        return "secondary";
    }
  };

  const customToast = (message: string) => {
    const splitMessage = message.split(".");
    const firstLine = splitMessage[0].trim();
    const secondLine = splitMessage[1] ? splitMessage[1].trim() : "";
    const thirdLine = splitMessage.slice(2).join(".").trim();
    toast.error(
      <div style={{ textAlign: "center" }}>
        <div>{firstLine}</div>
        <div>{secondLine}</div>
        <div> {thirdLine}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  const addAwbToTracking = async (awbNumber: string) => {
    // toastTypes.info("Fetching AWB details", true);
    try {
      const { data } = await lupaService.addAwbToTrackingMicro({ awbNumber });
      console.log({ data });
      if (data.message === "AWB added successfully") {
        toastTypes.success(data.message);
      } else {
        customToast(data.message);
      }
      refetch();

      return true;
    } catch (error: any) {
      if (
        error.response.data.message ===
        "Airline not yet live on Intemo platform"
      ) {
        customToast("Airline Not Live on.Intemo Platform");
      } else if (
        error.response.data.message ===
        "Access Denied. Tracking limit exceeded. Please contact admin."
      ) {
        customToast(
          "Access Denied. Tracking limit exceeded. Please contact admin."
        );
      } else if (
        error.response.data.message ===
        "AWB number must adhere to the format (e.g.,) 123-45678901. No Alphabets allowed."
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else if (
        error.response.data.message ===
        "Please enter a 11 digit AWB number (E.g.)123-45678901"
      ) {
        customToast("Please enter a 11 digit. AWB number.  (E.g.)123-45678901");
      } else {
        toast.error(error.response.data.message);
      }

      console.log(error.response.data.message);
      return false;
    }
  };
  const columns = useMemo<MRT_ColumnDef<AWBList>[]>(
    () => [
      // {
      //   header: "Name",
      //   filterFn: "includesString",
      //   accessorFn: (row) =>
      //     row.user.map((data) => data.firstName + " " + data.lastName),
      //   Cell: ({ cell }) =>
      //     cell.getValue<string[]>().map((data) => <div>{data}</div>),
      // },
      {
        header: "Email",
        accessorFn: (row) =>
          row.user.map((data) => {
            return data.email;
          }),

        filterVariant: "select",
        filterFn: "includesString",
        filterSelectOptions: (() => {
          const options = new Set<string>();
          data?.data.forEach((row) =>
            row.user.forEach((data) => options.add(data.email))
          );
          console.log(options);
          return Array.from(options).map((option) => ({
            text: option,
            value: option,
          }));
        })(),
        Cell: ({ cell }) =>
          cell.getValue<string[]>().map((data) => <div>{data}</div>),
      },
      {
        accessorKey: "awbNumber",
        header: `AWB No.`,
        Cell: ({ cell, row }) => (
          <Tooltip title="Click for more details" placement="top">
            <div
              className="awb-details-box"
              onClick={() => handleShowDetails(row.original.awbNumber)}
            >
              <div>{row.original.awbNumber}</div>
              <div className="info-circle">
                <InfoOutlinedIcon />
                {/* 'i' icon */}
              </div>
            </div>
          </Tooltip>
        ),
        minSize: 50,
        maxSize: 50,
        size: 50,
      },
      {
        accessorKey: "origin",
        header: "Origin",
        accessorFn: (row) => row.origin,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return value ? <Badge bg="dark">{value}</Badge> : "";
        },
      },
      {
        accessorKey: "destination",
        header: "Destination",
        accessorFn: (row) => row.destination,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return value ? <Badge bg="dark">{value}</Badge> : "";
        },
      },
      {
        accessorKey: "pieces",
        header: "Pieces",
        accessorFn: (row) => row.pieces,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string | number;
          return value ? <Badge bg="info">{value}</Badge> : "";
        },
      },
      {
        accessorKey: "trackingStatus",
        header: "Tracking Status",
        accessorFn: (row) => row.trackingStatus,
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return value ? <Badge bg={getBadgeColor(value)}>{value}</Badge> : "";
        },
      },
      {
        header: "Remarks",
        accessorFn: (row) => {
          console.log("Remarks column - row data:", row);
          return row.hasRemarks === false ? (
            <>
              <i className="bi bi-x-circle-fill text-danger"></i>
              {/* <span className="badge bg-danger ms-2">No Remarks</span> */}
            </>
          ) : (
            <>
              <i className="bi bi-check-circle-fill text-success"></i>
              {/* <span className="badge bg-success ms-2">Has Remarks</span> */}
            </>
          );
        },
        filterFn: (row, id, filterValue) => {
          if (filterValue === "") {
            return true; // Show all rows when filter value is empty
          }
          const filterBoolean = filterValue === "true";
          return row.original.hasRemarks === filterBoolean;
        },
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Has Remarks", value: "true" },
          { text: "No Remarks", value: "false" },
        ],
      },
      {
        header: "Data Source",
        accessorFn: (row) => {
          const statusLabels = [];
          if (row.isParcel) statusLabels.push("Parcel");
          if (row.isScraping) statusLabels.push("Scraping");
          if (row.isGocomet) statusLabels.push("Gocomet");

          return statusLabels.join(", ");
        },
        Cell: ({ row }) => (
          <div>
            {row.original.isParcel && <Badge bg="primary">Parcel</Badge>}
            {row.original.isScraping && <Badge bg="primary">Scraping</Badge>}
            {row.original.isGocomet && <Badge bg="primary">Gocomet</Badge>}
          </div>
        ),
      },
      {
        header: "Offload",
        accessorFn: (row) => {
          return row.offload === false ? (
            <>
              <i className="bi bi-x-circle-fill text-danger"></i>
              {/* <span className="badge bg-danger ms-2">Not Offloaded</span> */}
            </>
          ) : (
            <>
              <i className="bi bi-check-circle-fill text-success"></i>
              {/* <span className="badge bg-success ms-2">Offloaded</span>   */}
            </>
          );
        },
        filterFn: (row, id, filterValue) => {
          if (filterValue === "") {
            return true; // Show all rows when filter value is empty
          }
          const filterBoolean = filterValue === "true";
          return row.original.offload === filterBoolean;
        },
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Offloaded", value: "true" },
          { text: "Not Offloaded", value: "false" },
        ],
      },
      {
        header: "Final Status",
        accessorFn: (row) => row.finalStatus,
        Cell: ({ cell }) => (
          <span className="badge bg-success ms-2">{cell.getValue<string>()}</span>
        ),
        filterFn: (row, id, filterValue) => {
          return typeof row.original.finalStatus === "string" &&
            row.original.finalStatus.toUpperCase() === filterValue.toUpperCase();
        },
        filterVariant: "select",
        filterSelectOptions: [
          { text: "DELIVERED", value: "DELIVERED" },
          { text: "TRACKING_ENDED", value: "TRACKING_ENDED" },
          { text: "INACTIVE_INCORRECT", value: "INACTIVE_INCORRECT" },
          { text: "LIVE", value: "LIVE" }
        ],

      },



      // {
      //   header: "Airline Code",
      //   accessorFn: (row) => row.airlineCode,
      //   filterVariant: "select",
      //   filterSelectOptions: (() => {
      //     const options = new Set<string>();
      //     data?.data.forEach((row) => options.add(row.airlineCode));
      //     return Array.from(options).map((option) => ({
      //       text: option,
      //       value: option,
      //     }));
      //   })(),
      //   filterFn: "contains",
      // },
      {
        accessorKey: "createdDateTime",
        header: "Date of Entry",
        size: 150,
        accessorFn: (row) =>
          row.createdDateTime ? new Date(row.createdDateTime) : null,
        filterVariant: "date-range",
        filterFn: (row, columnId, filterValue) => {
          const [min, max] = filterValue || [];
          const date = row.getValue<Date>(columnId);
          const minDate = min ? new Date(min) : null;
          const maxDate = max ? new Date(max) : null;
          return (!minDate || date >= minDate) && (!maxDate || date <= maxDate);
        },
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<Date>();
          return dateValue ? dateValue.toLocaleString() : "-";
        },
      },
      {
        accessorKey: "LastupdatedDateTime",
        header: "Last Updated",
        size: 150,
        accessorFn: (row) =>
          row.LastupdatedDateTime ? new Date(row.LastupdatedDateTime) : null,
        filterVariant: "date-range",
        filterFn: (row, columnId, filterValue) => {
          const [min, max] = filterValue || [];
          const date = row.getValue<Date>(columnId);
          const minDate = min ? new Date(min) : null;
          const maxDate = max ? new Date(max) : null;
          return (!minDate || date >= minDate) && (!maxDate || date <= maxDate);
        },
        Cell: ({ cell }) => {
          const dateValue = cell.getValue<Date>();
          return dateValue ? dateValue.toLocaleString() : "-";
        },
      },
      // {
      //   header: "ETD/ETA Status",
      //   accessorFn: (row) =>
      //     row.etdEtaUpdate === true ? (
      //       <i className="bi bi-check-circle-fill text-success"></i>
      //     ) : (
      //       <i className="bi bi-x-circle-fill text-danger"></i>
      //     ),
      //   filterFn: webhookSentFilterFn,
      // },
      // {
      //   header: "ETD/ETA",
      //   accessorFn: (row) => row.toggle,
      //   Cell: ({ cell, row }) => {
      //     const isDisabled = row.original.etdEtaUpdate; // Check if etdEtaUpdate is true

      //     return (
      //       <input
      //         type="checkbox"
      //         checked={checkboxState}
      //         disabled={isDisabled}
      //         onChange={(e) =>
      //           handleToggle(row.original.awbNumber, e.target.checked)
      //         }
      //       />
      //     );
      //   },
      // },


      {
        header: "Action",
        accessorFn: (row) => row.awbNumber,
        enableColumnFilters: false,
        enableSorting: false,
        Cell: ({ cell, row }) => (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setShowDelete({ show: true, awbNumber: row.original.awbNumber });
            }}
          >
            Delete
          </Button>
        ),
      },
    ],
    [data]
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.data ?? [],
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true, density: "compact" },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: data?.data.length ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
        <h6 className="mt-2">
          No of AWBs: {table.getFilteredRowModel().rows.length}{" "}
        </h6>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportToExcel(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          All Rows(Excel)
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportToExcel(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Page Rows(Excel)
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportToExcel(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Selected Rows(Excel)
        </Button>
      </Box>
    ),
  });

  return (
    <Base>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container fluid className="mt-3">
          <SearchAwbFE addAwbToTracking={addAwbToTracking} />

          <MaterialReactTable table={table} />
          <Modal
            show={showDetails}
            onHide={handleCloseDetails}
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>AWB Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Array.isArray(selectedAWBDetails) &&
                selectedAWBDetails.length > 0 ? (
                selectedAWBDetails.map((awbDetail, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <FormattedDataDisplay data={awbDetail} />
                  </div>
                ))
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontSize: "18px",
                  }}
                >
                  No data found
                </div>
              )}
            </Modal.Body>
          </Modal>
        </Container>
        {showDelete.show && (
          <ConfirmActionPopup
            show={showDelete.show}
            onHide={() => setShowDelete(defaultShowDelete)}
            onSubmit={() => handleDeleteSubmit(showDelete.awbNumber!)}
            trackingNumber={showDelete.awbNumber}
            header="Delete AWB"
            confirmText={
              `Are you sure you want to delete this <strong>${showDelete.awbNumber}</strong> AWB?`
            }
          />
        )}
      </LocalizationProvider>
    </Base>
  );
}

export default AWBUsage;
