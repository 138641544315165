import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { RoutesConfig } from "./utils/RoutesConfig";
import GeneratePassword from "./components/pages/account/GeneratePassword";
import AccountCreate from "./components/pages/account/create/AccountCreate";
import AWBUsage from "./components/pages/clientadmin/AWBUsage";

import ClientBilling from "./components/pages/clientadmin/Billing";
import { TrackingDashboard } from "./components/pages/clientadmin/Demo";
import ClientLogin from "./components/pages/clientadmin/Login";
import ProfileContainer from "./components/pages/clientadmin/ProfileContainer";
import ApiEval from "./components/pages/flightschedule/ApiEval";
import Log from "./components/pages/logs";
import RequireAuth, {
  RequireAdminAccess,
  RequireUserApiLupaToken,
} from "./hooks/RequireAuth";

import RouteSelector from "./components/pages/clientadmin/RouteSelector";
import TrackingUserList from "./components/pages/userlist/UserList";
import envConfig from "./utils/envConfig";
// import ProfileContainer from "./components/pages/clientadmin/ProfileContainer";
import UserBilling from "./components/pages/UserBilling/UserBilling";
import ForgotPassword from "./components/pages/forgotpassord";
import AirlineInfo from "./components/pages/tracking/AirlineInfo";
import AwbTable from "./components/pages/clientadmin/WebhookData";
import RawDataDisplay from "./components/pages/clientadmin/WebhookRawData";
import AdminDashboard from "./components/pages/clientadmin/AdminDashboard";
import { UserDemo } from "./components/pages/clientadmin/WebhookTracking";
import ETDETADisplay from "./components/pages/clientadmin/EtdEtaDisplay";
import Scraping from "./components/pages/clientadmin/Scraping";
import DataCheck from "./components/pages/clientadmin/DataCheck";
import UploadHistory from "./components/pages/clientadmin/UploadHistory";
import ManualWebhookLog from "./components/pages/clientadmin/ManualWebhookLog";
import UnsupportedAirlines from "./components/pages/clientadmin/UnsupportedAirline";
import AWBDetailsView from "./components/pages/clientadmin/AWBDetails";
import EditAWBForm from "./components/pages/clientadmin/EditAWBForm";
import EditAWBFormPage from "./components/pages/clientadmin/EditFormPage";
import ScrapingHealthCheck from "./components/pages/clientadmin/ScrapingHealth";
import ScrapingTrackings from "./components/pages/clientadmin/ScrapingTrackings";
import DeleteAdd from "./components/pages/clientadmin/DeleteAndAddAWB";
import ScrapingSettings from "./components/pages/settings/scrapingSettings";
import ScrapDeleteAddLog from "./components/pages/clientadmin/ScrapDeleteAddLog";
import DataSourceChangeTable from "./components/pages/clientadmin/DataSourceLog";
import DataSourceSwapLog from "./components/pages/clientadmin/DataSourceSwapLog";
import logView from "./components/pages/clientadmin/logView";
import GridComponent from "./components/pages/clientadmin/logView";
import Datacompare from "./components/pages/clientadmin/DataCompare/DataCompareDs";
import DataView from "./components/pages/clientadmin/DataCompare/DataView";
import TestWebhook from "./components/pages/clientadmin/WebhookTestPage/TestWebhook";
import Airline from "./components/pages/tracking/AvailableAirline";
import ViewWebhook from "./components/pages/clientadmin/WebhookTestPage/ViewWebhook";
import EmailLogTable from "./components/pages/clientadmin/WebhookTestPage/EmailLogTable";
import UserAirlineMappingPage from "./components/pages/tracking/UserAirlineMapping";
import CourierUsage from "./components/pages/clientadmin/CourierUsage";
import AddAWB from "./components/pages/clientadmin/WebhookTestPage/AddAwb";
import CombinePage from "./components/pages/clientadmin/CombinePage";
import UserWebhookMapping from "./components/pages/clientadmin/WebhookPreference";
const BASE_URL = envConfig.url.API_URL;
const routesConfig: RoutesConfig = new RoutesConfig();

export const AppRoutes: FunctionComponent<{}> = () => {
  return (
    <Routes>
      {/* <Route path = { routesConfig.login.path } element = { <AppLogin />}></Route>     */}
      {/* <Route path={routesConfig.login.path} element={<AppLogin />}></Route> */}
      <Route
        path={routesConfig.createShipper.path}
        element={<AccountCreate />}
      ></Route>
      <Route
        path={routesConfig.generatePassword.path}
        element={<GeneratePassword />}
      ></Route>
      <Route
        path={routesConfig.flightScheduleDemo.path}
        element={<ApiEval />}
      ></Route>
      <Route element={<RequireAuth />}>
        {/* <Route path={routesConfig.home.path} element={<Home />}></Route> */}
        {/* <Route
          path={routesConfig.manageShipper.path}
          element={<ManageShippers />}
        ></Route>
        <Route
          path={routesConfig.addShipper.path}
          element={<AddShipper />}
        ></Route>
        <Route
          path={routesConfig.editShipper.path}
          element={<EditShipper />}
        ></Route>
        <Route
          path={routesConfig.manageConsignee.path}
          element={<ManageConsignee />}
        ></Route>
        <Route
          path={routesConfig.addConsignee.path}
          element={<AddConsignee />}
        ></Route>
        <Route
          path={routesConfig.editConsignee.path}
          element={<EditConsignee />}
        ></Route>
        <Route
          path={routesConfig.manageShipperCategory.path}
          element={<ManageShipperCategory />}
        ></Route>
        <Route
          path={routesConfig.addShipperCategory.path}
          element={<AddShipperCategory />}
        ></Route>
        <Route
          path={routesConfig.editShipperCategory.path}
          element={<EditShipperCategory />}
        ></Route>
        <Route
          path={routesConfig.newBooking.path}
          element={<NewBookingWorkflow />}
        ></Route>
        <Route
          path={routesConfig.bookOrder.path}
          element={<BookAirlineOrder />}
        ></Route>
        <Route
          path={routesConfig.viewBooking.path}
          element={<ViewBooking />}
        ></Route> */}
      </Route>
      {/* <Route path={routesConfig.trackingLogin.path} element={<Login />}></Route>
      <Route
        path={routesConfig.trackingAdminLogin.path}
        element={<AdminLogin />}
      ></Route>
      <Route
        path={routesConfig.trackingRegister.path}
        element={<TrackingRegister />}
      ></Route> */}
      <Route element={<RequireAuth />}>
        {/* <Route path={routesConfig.trackingHome.path} element={<SearchAwb />}></Route> */}
        <Route
          path={routesConfig.trackingDashboard.path}
          element={<TrackingDashboard />}
        ></Route>
        <Route
          path={routesConfig.trackingUserList.path}
          element={<TrackingUserList />}
        ></Route>
        <Route element={<RequireAdminAccess />}>
          <Route
            path={routesConfig.awbUsage.path}
            element={<AWBUsage />}
          ></Route>
        </Route>

        <Route element={<RequireAdminAccess />}>
          <Route
            path={routesConfig.courierUsage.path}
            element={<CourierUsage />}
          ></Route>
        </Route>

        <Route element={<RequireAdminAccess />}>
          <Route
            path={routesConfig.UnsupportedAirlines.path}
            element={<UnsupportedAirlines />}
          ></Route>
        </Route>

        <Route
          path={routesConfig.airlineInfo.path}
          element={<AirlineInfo />}
        ></Route>
      </Route>
      <Route
        path={routesConfig.trackingAdminDemo.path}
        element={<TrackingDashboard />}
      ></Route>
      <Route
        path={routesConfig.clientLogin.path}
        element={<ClientLogin />}
      ></Route>
      <Route
        path={routesConfig.clientLogin.path}
        element={<ClientLogin />}
      ></Route>
      <Route
        path={routesConfig.forgotPassword.path}
        element={<ForgotPassword />}
      ></Route>
      <Route
        path={routesConfig.clientOldLogin.path}
        element={<Navigate to={routesConfig.clientLogin.path} replace />}
      />
      <Route
        path="*"
        element={<Navigate to={routesConfig.clientLogin.path} replace />}
      />
      <Route
        path="/home"
        element={<Navigate to={routesConfig.clientLogin.path} replace />}
      />

      <Route element={<RequireUserApiLupaToken />}>
        <Route
          path={routesConfig.clientDashBoard.path}
          element={<RouteSelector />}
        ></Route>
        <Route
          path={routesConfig.availableAirline.path}
          element={<Airline />}
        ></Route>
        <Route
          path={routesConfig.addAwb.path}
          element={<AddAWB />}
        ></Route>
        <Route
          path={routesConfig.clientProfile.path}
          element={<ProfileContainer />}
        ></Route>
        <Route
          path={routesConfig.clientBilling.path}
          element={<ClientBilling />}
        ></Route>
        <Route
          path={routesConfig.userBilling.path}
          element={<UserBilling />}
        ></Route>
        <Route path={routesConfig.log.path} element={<Log />}></Route>
      </Route>
      <Route
        path={routesConfig.webhookData.path}
        element={<AwbTable />}
      ></Route>
      <Route
        path={routesConfig.webhookRawData.path}
        element={<RawDataDisplay />}
      ></Route>
      <Route path={routesConfig.userDemo.path} element={<UserDemo />}></Route>
      <Route
        path={routesConfig.etdeta.path}
        element={<ETDETADisplay />}
      ></Route>
      <Route path={routesConfig.scraping.path} element={<Scraping />}></Route>
      <Route path={routesConfig.dataCheck.path} element={<DataCheck />}></Route>
      <Route
        path={routesConfig.awbDetails.path}
        element={<AWBDetailsView />}
      ></Route>
      <Route
        path={routesConfig.combinePage.path}
        element={<CombinePage />}
      ></Route>
      <Route
        path={routesConfig.awbEdit.path}
        element={<EditAWBFormPage />}
      ></Route>
      <Route
        path={routesConfig.userAirlineMapping.path}
        element={<UserAirlineMappingPage />}
      ></Route>
      <Route
        path={routesConfig.scrapingHealth.path}
        element={<ScrapingHealthCheck />}
      ></Route>
      <Route
        path={routesConfig.scrapingTracking.path}
        element={<ScrapingTrackings />}
      ></Route>
      <Route path={routesConfig.deleteAdd.path} element={<DeleteAdd />}></Route>
      <Route
        path={routesConfig.uploadHistory.path}
        element={<UploadHistory />}
      ></Route>
      <Route
        path={routesConfig.manualWebhookLog.path}
        element={<ManualWebhookLog />}
      ></Route>
      <Route
        path={routesConfig.scrapDeleteAddLog.path}
        element={<ScrapDeleteAddLog />}
      ></Route>
      <Route
        path={routesConfig.scrapSetting.path}
        element={<ScrapingSettings />}
      />
      <Route
        path={routesConfig.dataSourceLog.path}
        element={<DataSourceChangeTable />}
      ></Route>
      <Route
        path={routesConfig.dataSourceSwapLog.path}
        element={<DataSourceSwapLog />}
      ></Route>
      <Route
        path={routesConfig.logView.path}
        element={<GridComponent />}
      ></Route>
      <Route
        path={routesConfig.scrapSetting.path}
        element={<ScrapingSettings />}
      />

      <Route path={routesConfig.dataCompare.path} element={<Datacompare />} />
      <Route path={routesConfig.dataView.path} element={<DataView />}></Route>
      <Route
        path={routesConfig.webhookTest.path}
        element={<TestWebhook />}
      ></Route>
      <Route
        path={routesConfig.webhookTestLog.path}
        element={<EmailLogTable />}
      ></Route>
      <Route
        path={routesConfig.webhookView.path}
        element={<ViewWebhook />}
      ></Route>

      <Route path={routesConfig.userWebhookMapping.path} element={<UserWebhookMapping />} />

      {/*
        <Route path="*" element={<div>Not found</div>} />
    <Route path = { routesConfig.viewRatesShipper.path } element = { <ViewRatesForShipper /> }></Route>
    <Route path = { routesConfig.dataSettings.path } element = {<DataSettings />}></Route>
    <Route path = { routesConfig.viewOrder.path } element = {<ViewOrder />}></Route>
    <Route path = { routesConfig.updateOrder.path } element = {<UpdateOrder />}></Route>
    <Route path = { routesConfig.updateOrderStatus.path } element = {<UpdateOrderStatus />}></Route>
    <Route path = { routesConfig.updateOrderWeight.path } element = {<UpdateOrderWeight />}></Route>
    <Route path = { routesConfig.updateOrderDocuemnt.path } element = {<UpdateOrderDocuments />}></Route>
    */
      /*
    <Route path = { routesConfig.manageRates.path } element = {<ManageRates />}></Route>
    <Route path = { routesConfig.manageWallet.path } element = {<ManageWallet />}></Route>
    <Route path = { routesConfig.accountViewWallet.path } element = {<AccountViewWallet />}></Route> */}
    </Routes>
  );
};
