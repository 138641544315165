import { Organization } from "./Organization";

export enum UserRole {
  ORDER_MANAGEMENT,
  ORDER_FULFILLMENT,
  SHIPPER,
  FF_ADMIN,
}

export class User {
  public readonly userId: string;
  public readonly firstName: String;
  public readonly lastName: String;
  public readonly org: Organization;
  public readonly role: UserRole;
  public readonly email: string;

  public constructor(
    userId: string,
    firstName: String,
    lastName: String,
    role: UserRole,
    org: Organization,
    email: string = ""
  ) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.org = org;
    this.role = role;
    this.email = email;
  }
}

export type CreateUserUpload = {
  token: string;
  firstName: string;
  lastName: string;
  baseLocation: string;
  countryCode: string;
  phone: string;
  password: string;
};

export interface UpdateUserAdmin {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  monthlyVolume: any;
  role: string;
}
export interface UserListdetail {
  userType: any;
  id: number;
  email: string;
  shipperId: string;
  phone: string;
  UserClaims: any;
  firstName: string;
  lastName: string;
  role: string[];
  awbCount: number;
  orgType: string;
  isActive: boolean;
}
